<template>
    <router-link :to="to">
        <div class="link-container">
            <span class="body-regular-14">{{ label }}</span>
            <IconSax :name="icon" v-if="icon" />
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            default: "",
        },
        to: {
            type: String,
            default: "",
        },
    },
};
</script>

<style lang="scss" scoped>
.link-container {
    @include flex-row;
    gap: 0.5rem;
    color: var(--maincolor);
    cursor: pointer;
    align-items: center;

    &:hover {
        text-decoration: underline;
    }

    ::v-deep .svgCss {
        svg {
            width: 0.875rem;
            height: 0.875rem;
        }

        svg path {
            fill: var(--maincolor) !important;
        }
    }
}
</style>